import React from 'react'
import './skills.css'
import {BsPatchCheckFill} from 'react-icons/bs'


const skills = () => {
  return (
    <section id = 'skills'>
      <h2>My Skills</h2>
       <div className="container skills__container">
        {/* ======= ML AI Skills ============= */}
        <div className="skills__ML_DL">
          <h3>Machine Learning, Artificial Intelligence</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Python</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>PyTorch</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Sci-Kit Learn</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Tensorflow</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Natural Language Processing</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Machine Learning Operations</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Recommendation Systems</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Forecasting</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Computer Vision</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Synthetic Data Generation</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

          </div>
        </div>

        {/* ================== Data Science Skills =========== */}
        <div className="skills__data_science">
          <h3>Data Science/Engineering</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>SQL</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>NoSQL</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Pandas</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Data Visualization</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Plotly</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>PowerBi</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>PySpark</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Big Data</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Apache Airflow, Kafka</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Alteryx</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

          </div>
        </div>

        {/*  ============= Cloud Skills =============== */}
        <div className="skills__cloud">
          <h3>Cloud</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>AWS</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Azure</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>GCP</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Microservices</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Git</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>REST API</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>
        </div>

        {/* ====== Frontend Development ======= */}
        <div className="skills__frontend">
          <h3>Frontend Development</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>HTMl</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>CSS</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Javascript</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>React</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>
        </div>

        {/* ========= Backend Development ============== */}

        <div className="skills__cloud">
          <h3>Backend Development</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Node JS</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>MongoDB</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Java</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>C++</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>C</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>
        </div>


        {/* =========  Miscellanous ============== */}

              <div className="skills__other">
          <h3>Other</h3>
          <div className="skills__content">

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Project Management</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Coaching</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Stakeholder Management</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className="skills__details">
              <BsPatchCheckFill className='skills__details-icon'/>
              <div>
              <h4>Product management</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>
        </div>

       </div>



    </section>
  )
}

export default skills