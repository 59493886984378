import React from 'react'
import './portfolio.css'


const portfolio = () => {
  return (
    <section id = 'portfolio'>
      <h2>PORTFOLIO</h2>
      <h5>The projects I have pursued dusring the course of my academic and professional career</h5>
      <div className="container__portfolio">

        <div className='portfolio__project defense'>
          <h3>Defense Budget Analysis</h3>
          <h5>Analysed the defense budget of USA to find out abnormalities using supervised and unsupervised machine Learning</h5>
        </div>

        <div className='portfolio__project MDM'>
          <h3>Master Data Management</h3>
          <h5>Devloped a search and reccomendation tool using unsupervised machine learning, for SAP master 
            data spanning over 40 million rows
          </h5>
        </div>

        <div className='portfolio__project AIwellness'>
          <h3>AI for Wellnesss</h3>
          <h5>Assessed the feasibility of using AI for imporving wellness in a large corporate buildings.
          </h5>
        </div>

        <div className='portfolio__project BrewAI_AutoML'>
          <h3>Design and Development of AutoML Tool</h3>
          <h5>Found new areas of development for improving the AutoML tool at BrewAI.
          </h5>
        </div>

        <div className='portfolio__project NAA'>
          <h3>Development of Nueral Assistant Agent Framework for Customer Support</h3>
          <h5>
          Building Agent Assistants that can help improve customer service support requires inputs
          from industry users and their customers, as well as knowledge about state-of-the-art 
          Natural Language Processing (NLP) technology.
          </h5>
        </div>

        <div className='portfolio__project FinReporting'>
          <h3>Automating Financial Reporting for Federal Agency</h3>
          <h5>Developed and end to end pipeline in GCP to automatically ingest data from accounting softwares and 
            generate the necessary alerts and dashboards to report experditure.
          </h5>
        </div>

        <div className='portfolio__project HateCrime'>
          <h3>Tracking Hate Crime</h3>
          <h5>Designed an end to end pipeline for performing topic modelling on various news paper articles to track
            hate crime in various counties of canada. The reporting and UI interface was developed and deployed on PowerBI. 
          </h5>
        </div>


        <div className='portfolio__project CapitalOptimization'>
          <h3>Data Driven Capital Optimization</h3>
          <h5>Collaborated with the strategy team and the leadership in on eof the biggest crown corporations of canada to assist in
            reducing annual budget expenditure by 600 million dollars by prioritizing projects to be funded over ones whose funding can be delayed. 
          </h5>
        </div>

        <div className='portfolio__project LGRForecasting'>
          <h3> Forecasting Liquid to Gas Ratio Using machine Learning</h3>
          <h5>
            Participated in analysis and predictive modelling of data pertaining to the oil and gas industry 
            to create a model that could be used to predict the liquid to gas ratio at any given location in Alberta.  
          </h5>
        </div>

        <div className='portfolio__project PBEsolver'>
          <h3>Predicting Breakage and Aggregation of Bubbles</h3>
          <h5>
            Developed a numerical solver in Python to predict the beakage and aggregation of 
            bubbles or droplets in a turbulent environment.
          </h5>
        </div>

        <div className='portfolio__project VFM'>
          <h3>Developed a Vitual Flow Meter Using Machine Learning</h3>
          <h5>
            Led design, development and deployment of a data driven predictive model that can
            replace a mechanical device responsible for measuring flow rates of different fluids (oil, gas and water) 
            from an oil well.
          </h5>
        </div>

        <div className='portfolio__project SQLyelp'>
          <h3>Querying Yelp Database</h3>
          <h5>
            Used SQL to perform the necessary extract transform and load (ETL).
          </h5>
        </div>

        <div className='portfolio__project RecommendationSystem'>
          <h3>Recommendation System - Food recipes</h3>
          <h5>
          Used Natural language processing (NLP) to associate recipes across various cuisines. 
          I used the famous nltk library for cleaning the text data (i.e stemming, lemmatizing, removing stop words, 
          removing special characters, etc). We used various unsupervised learning algorithms (KNN, Multilayer perceptrons, 
          Random Forest, and Naive Bayes) to club and cluster over 60000 recipes spread across more than 100 cuisines.
          </h5>
        </div>

        <div className='portfolio__project staticMixer'>
          <h3>Understanding Mixing in a static Mixer</h3>
          <h5>
          Performed computational Fluid Dynamics (CFD) simulations using high performance computing to understanding the 
          mixing of immiscible fluids when passed through a static mixer.
          </h5>
        </div>

        <div className='portfolio__project GMMStaticMixer'>
          <h3>Machine Learning to Understand Mixing</h3>
          <h5>
          Used Gaussian Mixture Models (GMMs) to capture the multi modal distributions that were created when a fluid is
          passed through the static mixer.
          </h5>
        </div>

        
      </div>
      <h2>Will keep adding more as curiosity may never die . . . .</h2>
    </section>
  )
}

export default portfolio