import React from 'react'
import './experience.css'
import BrewAiLogo from '../../assets/brewaiLogo.png'
import PwClogo from '../../assets/PwC_Logo.png'
import Altaml from '../../assets/altamlLogo.png'
import QuantumDesign from '../../assets/QuantumLogoTra.png'
import Untribe from '../../assets/PolySeamTrans.png'
import albertaloop from '../../assets/Albertaloop.png'
import Ualberta from '../../assets/Ualberta.png'



const experience = () => {
  return (
    <section id ='experience'>
      
      <h2>WORK EXPERIENCE</h2>

      <div className="container experience__timeline">
        <div className="experience__parent">
          
          <div className="content__experience left">
            <div className="experience__image">
              <img src= {BrewAiLogo} alt="" />
            </div>
            <div>
              <h2>BrewAI</h2>
              <h3>Data Sceince Manager/Product Manager</h3>
              <h4>2022 - Present</h4>
              <p> 
              I Partnered with Product and Engineering teams to solve problems and 
              identify trends and opportunities. Manage development of ML, AI and data resources,
              gather requirements, organize sources, and support product launches and 
              data science projects. 
              </p>
            </div>
          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience right">
            <div className="experience__image">
              <img src= {PwClogo} alt="" />
            </div>
            <div>
              <h2>PwC Canada</h2>
              <h3>Senior Associate/Senior Data Scientist</h3>
              <h4>2021 - 2022</h4>
              <p> 
              As a Senior Associate, I worked as part of the One Analytics team
              to solve problems pertaining to data science, machine learning and 
              Artificial Intelligence.
              </p>
            </div>

          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience left">
            <div className="experience__image">
              <img src= {Altaml} alt="" />
            </div>
            <div>
              <h2>AltaML</h2>
              <h3>Associate Machine Learning Developer</h3>
              <h4>July - Sep, 2021</h4>
              <p> 
              As Machine Learning Developer at AltaMl I was responsible for design,
              development and deployment of a machine learning model on Azure. I was responsible
              for communicating the efforts of the ML development team to the client.
              </p>
            </div>

          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience right">
            <div className="experience__image">
              <img src= {QuantumDesign} alt="" />
            </div>
            <div>
              <h2>Quantum Design</h2>
              <h3>Machine Learning Assistant</h3>
              <h4>Jan - July, 2022</h4>
              <p> 
              As Machine Learning assistant at Quantum design and technologies,
              I developed, designed, deployed and monitored the anomaly detection 
              system. I independently designed a machine learning model to detect and classify 
              various anomalies in vibration and pressure sensor data.
              </p>
            </div>

          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience left">
            <div className="experience__image">
              <img src= {Untribe} alt="" />
            </div>
            <div>
              <h2>Untribe</h2>
              <h3>Machine Learning Software developer</h3>
              <h4>July - Nov, 2021</h4>
              <p> 
                I was responsible for design and development of a 
                recommendation system for connecting music teachers with 
                appropriate students.
              </p>
            </div>

          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience right">
            <div className="experience__image">
              <img src= {albertaloop} alt="" />
            </div>
            <div>
              <h2>Albertaloop</h2>
              <h3>Mechanical Team Lead</h3>
              <h4>2019 - 2022</h4>
              <p> 
                I led a student team of about 20 students to design, develop
                and manufacture a mechanical chasis and the outer shell for 
                a hyperloop pod.
              </p>
            </div>

          </div>
        </div>

        <div className="experience__parent">
          
          <div className="content__experience left">
            <div className="experience__image">
              <img src= {Ualberta} alt="" />
            </div>
            <div>
              <h2>University of Alberta</h2>
              <h3>Graduate Research Assistant</h3>
              <h4>2018 - 2021</h4>
              <p> 
                I pursued research in the field of Co,putational Fluid Dynamics
                and multiphase flows. Developed a numerical solver to simulate the breakage and 
                aggregation of droplets and bubbles.
              </p>
            </div>

          </div>
        </div>



      </div>
     
    </section>


  )
}

export default experience