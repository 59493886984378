import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/Nav'
import About from  './components/About/About'
import Skills from './components/skills/skills'
import Experience from './components/experience/experience'
// import Research from './components/research/research'
// import Playground from './components/playground/playground'
import Contact from './components/contact/Contact'
import Footer from './components/footer/footer'
import Portfolio from './components/portfolio/portfolio'

const App = () => {
  return (
    <>
        <Header />
        <Nav />
        <About />
        <Skills />
        <Experience />
        <Portfolio/>
        {/* <Research /> */}
        {/* <Playground /> */}
        
        <Contact />
        <Footer />
    </>
  )
}

export default App