import React from 'react'
import './Nav.css'
import {FaHome} from 'react-icons/fa'
import {FaUser} from 'react-icons/fa'
import {SiSmartthings} from 'react-icons/si'
import {MdWork} from 'react-icons/md'
// import {MdSchool} from 'react-icons/md'
import {IoBuild} from 'react-icons/io5'
// import {GiArchiveResearch} from 'react-icons/gi'
// import {AiFillPlaySquare} from 'react-icons/ai'
import {RiMessage2Fill} from 'react-icons/ri'
import {useState} from 'react'


const Nav = () => {
  const [activeNav, setActiveNav] = useState('#home')
  return (
    <nav>
      {/* For Home Icon */}
      <a href="#home" onClick={() => setActiveNav('#home')} className= {activeNav === '#home' ? 'active' : ''}><FaHome/></a>
      {/* <div className='hoverHome'>Home</div> */}

      {/* For About Icon */}
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : '' }><FaUser/></a>

      {/* For skills Icon */}
      <a href="#skills" onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : '' }><SiSmartthings/></a>

      {/* For work experience Icon */}
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : '' }><MdWork/></a>

      {/* For work education Icon */}
      {/* <a href="#education" onClick={() => setActiveNav('#education')} className={activeNav === '#education' ? 'active' : '' }><MdSchool/></a> */}

      {/* For portfolio Icon */}
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : '' }><IoBuild/></a>

      {/* For Research Icon */}
      {/* <a href="#research" onClick={() => setActiveNav('#research')} className={activeNav === '#research' ? 'active' : '' }><GiArchiveResearch/></a> */}

      {/* For playground  Icon */}
      {/* <a href="#playground" onClick={() => setActiveNav('#playground')} className={activeNav === '#playground' ? 'active' : '' }><AiFillPlaySquare/></a> */}

      {/* For contact  Icon */}
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : '' }><RiMessage2Fill/></a>
    </nav>
    )
}

export default Nav