import React, { useRef } from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {FaFacebookMessenger} from 'react-icons/fa'
import {IoLogoWhatsapp} from 'react-icons/io'
import emailjs from '@emailjs/browser';


const Contact = () => {

  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qw9v2ey', 'template_hvpxsdi', form.current, 'wvqblmJE7O3MaBNV1')

    e.target.reset()
  };

  return (
    <section id = 'contact'>
      <h5>Get in Touch</h5>
      <h2>CONTACT ME</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>patelkanishk1995@gmail.com</h5>
            {/* eslint-disable-next-line */}
            <a href="mailto:patelkanishk1995@gmail.com" target="_blank">Send an email</a>
          </article>

          <article className='contact__option'>
            <FaFacebookMessenger className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Kanishk Patel</h5> 
            {/* eslint-disable-next-line */}
            <a href="https://m.me/kanishk.patel.963" target="_blank">Send a message</a>
          </article>

          <article className='contact__option'>
            <IoLogoWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>+1 587 938 5800</h5>
            {/* eslint-disable-next-line */}
            <a href="https://api.whatsapp.com/send?phone+15879385800" target="_blank">Send a text</a>
          </article>

        </div>
        {/* End of contact options */}
        <form ref= {form} onSubmit={sendEmail}>
          <input type="text" name = 'Name' placeholder='Your Full Name' required />
          <input type="text" name='Email' placeholder='Your email' required/>
          <textarea name="Message" rows="7" placeholder='Your Message'></textarea>
          <button type ='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact