import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'


const HeaderSocial = () => {
  return (
    <div className='header__social'>
        <a href="https://www.linkedin.com/in/kanishk-patel/" target =""><BsLinkedin size = {35}/></a>
        <a href="https://github.com/kanishkpatel1995" target =""><BsGithub size = {35}/></a>
    </div>
    
  )
}

export default HeaderSocial