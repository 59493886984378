import React from 'react'
import './About.css'
import MeCamera from '../../assets/Me_camera.jpeg'
// import {FaAward} from 'react-icons/fa'
// import {FaUniversity} from 'react-icons/fa'

const about = () => {
  return (
    <section id='about'>
      <h2>ABOUT ME</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src= {MeCamera} alt="About_Image" />

          </div>

        </div>

        <div className="about__content">

          {/* <div className="about__cards">

            <article className="about__card">
              <FaAward className='about_icon'/>
              <h5>Experience</h5>
              <small>6+ Years Working</small>
            </article>

            <article className="about__card">
              <FaUniversity className='about_icon'/>
              <h5>Masters</h5>
              <small>University Of Alberta</small>
            </article>

            <article className="about__card">
              <FaAward className='about_icon'/>
              <h5>Bachelors</h5>
              <small>Indian Institute of Technology, Dhanbad</small>
            </article>
          </div> */}

          <p>
          I am a highly motivated and passionate individual with a very versatile background. 
          I have a Bachelors in Mechancial Engineering from Indian Institute of Technology and
          master's in Mechanical Engineering from University of Alberta, where I pursued research in the field of numerical modelling and 
          succesfully defended my thesis in May, 2021. For visualizing data from 
          large simulations and improving the numerical models I used machine learning. Since then I have acted as a graduate research assistant,
           mechanical team lead, Machine Learning developer, Data Scientist, senior consultant, and now Data science manager.
          </p>
          {/* <p>
          Due to my versatile background, 
          I possess knowledge in various fields and so I can approach any problem with 
          knowledge from different subjects. My research background has empowered me 
          to be a strategic thinker, and my passion for data science, machine learning, 
          and Artificial Intelligence has equipped me with an undying curiosity. 
          My experience as a senior consultant has exposed me to various real-world problems, 
          has empowered me to quickly grasp any problem, and has given me the ability to 
          lead across various cross-functional teams. Currently, as a Data Science manager, 
          I work with the software development team and machine learning engineers to 
          deliver state-of-the-art solutions to clients.
          </p> */}

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>


        </div>

      </div>
    
    
    
    </section>
    

  )
}

export default about