import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me1.jpeg'
import HeaderSocial from './HeaderSocial'


const header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5> Hello I'm </h5>
        
        <div className="me">
          <img src= {ME} alt="me" />
        </div>

        
        <h1> Kanishk Patel </h1>
        <div className="text-light">  Manager Data Science </div>
        {/* buttons imported from a separate file */}
        <CTA/>
        <HeaderSocial/>
        

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
       </header>
  )
}

export default header